import { Row, Col, Tab, Tabs } from "react-bootstrap";
import MediaPlayer from "../components/MediaPlayer";
import Cameras from "../components/Cameras";
import VideoAnalysis from "../components/MediaAnalysis";
import { useContext, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import PageLayout from "../components/PageLayout";
import { getGroups } from "../services/Groups";
import { GroupItem } from "../models/Group";
import { CameraItem } from "../models/Camera";
import { SignInContext } from "../context/SignInContext";
import Spinner from "../components/Spinner";
import { useSelectedIdentifiedObjectContext } from "../context/IdentifiedObjectContext";

const GroupTabContent: React.FC<{
  groupId?: number;
  isSingleGroup: boolean;
}> = ({ groupId, isSingleGroup }) => {
  const [cameraItems, setCameraItems] = useState<CameraItem[]>([]);

  const handleCamerasLoaded = (loadedCameras: CameraItem[]) => {
    setCameraItems(loadedCameras);
  };
  const { resetState } = useSelectedIdentifiedObjectContext();
  useEffect(() => {
    resetState(); // Trigger a reset when groupId changes
  }, [groupId, resetState]);
  return (
    <Row className="mt-0">
      <Col md={12} xl={3} sm={12} xs={12} className="mt-0 px-2">
        <Cameras groupId={groupId} onCamerasLoaded={handleCamerasLoaded} />
      </Col>
      {cameraItems && cameraItems.length > 0 ? (
        <>
          {/* Place MediaPlayer first for small (sm) and medium (md) screens, but reorder for larger screens */}
          <Col
            md={12}
            xl={6}
            sm={12}
            xs={12}
            className="order-sm-1 order-md-1 order-lg-2 mt-3 px-2"
          >
            <MediaPlayer />
          </Col>
          {/* Place VideoAnalysis below MediaPlayer for sm and md screens, reordered on larger screens */}
          <Col
            md={12}
            xl={3}
            sm={12}
            xs={12}
            className="order-sm-2 order-md-2 order-lg-3 mt-3 px-2"
          >
            <VideoAnalysis />
          </Col>
        </>
      ) : (
        <Col
          md={12}
          xl={12}
          sm={12}
          xs={12}
          className="d-flex justify-content-center align-items-center mt-2 px-2"
        >
          <div>
            {isSingleGroup
              ? "No cameras available."
              : "No cameras available. Please try selecting another group."}
          </div>
        </Col>
      )}
    </Row>
  );
};

const Dashboard = () => {
  const auth = useAuth();
  const [groups, setGroups] = useState<GroupItem[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<number | undefined>(
    undefined
  );
  const isSingleGroup = groups && groups.length === 1;
  const { userGroupsCount } = useContext(SignInContext);
  const [loading, setLoading] = useState(true);

  const loadGroups = () => {
    setLoading(true);
    const getGroups$ = getGroups({ page: "all", userGroups: true });
    const sub = getGroups$.subscribe({
      next: (res: any) => {
        let filteredItems = res.filter(
          (item: GroupItem) =>
            item?.groupprop?.is_visible === true || !item?.groupprop
        );
        setGroups(filteredItems);
        if (filteredItems.length) setSelectedGroup(filteredItems[0]?.id);
        setLoading(false);
      },
    });
    return () => sub.unsubscribe();
  };

  useEffect(() => {
    userGroupsCount && loadGroups();
  }, [userGroupsCount]);

  const handleTabSelect = (groupId: number | null) => {
    setSelectedGroup(groupId !== null ? groupId : undefined);
  };

  return (
    <PageLayout title="Dashboard">
      {userGroupsCount == null && <Spinner />}

      {auth.isAuthenticated && userGroupsCount === 0 && (
        <p className="text-center px-3">
          You’re logged in but don’t currently have access to any cameras.
          Contact
          <b>
            <a href="mailto:test@example.edu.au" className="text-black">
              {" "}
              Admin
            </a>
          </b>{" "}
          to become a community member.
        </p>
      )}
      {auth.isAuthenticated &&
        userGroupsCount != null &&
        userGroupsCount > 0 && (
          <>
            {loading ? (
              <Spinner /> // Show spinner when loading groups
            ) : (
              <Row className="mt-2">
                {isSingleGroup ? (
                  <GroupTabContent isSingleGroup={isSingleGroup} />
                ) : (
                  <Tabs
                    activeKey={selectedGroup}
                    onSelect={(groupId) => handleTabSelect(Number(groupId))}
                    className="mb-3 flex-wrap"
                  >
                    {groups?.map((group) => (
                      <Tab
                        key={group.id}
                        eventKey={group.id}
                        title={group.name}
                      >
                        {selectedGroup === group.id && (
                          <GroupTabContent
                            groupId={group.id}
                            isSingleGroup={isSingleGroup}
                          />
                        )}
                      </Tab>
                    ))}
                  </Tabs>
                )}
              </Row>
            )}
          </>
        )}
    </PageLayout>
  );
};

export default Dashboard;
