import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getIdentifiedObjects } from "../services/Media";
import { IdentifiedObjectItem } from "../models/Media";
import { useSelectedIdentifiedObjectContext } from "../context/IdentifiedObjectContext";

interface IdentifiedObjectProps {
  mode: string;
}

const IdentifiedSpecies: React.FC<IdentifiedObjectProps> = ({ mode }) => {
  const [identifiedObjects, setIdentifiedObjects] = useState<
    IdentifiedObjectItem[]
  >([]);
  const {
    setSelectedIdentifiedObjects,
    setAllIdentifiedObjects,
    resetTrigger,
  } = useSelectedIdentifiedObjectContext();

  // Load identified objects
  const loadIdentifiedObjects = () => {
    getIdentifiedObjects().then((items: any) => {
      setIdentifiedObjects(items);
      setAllIdentifiedObjects(items);

      setSelectedIdentifiedObjects([]); // Reset context
    });
  };
  useEffect(() => {
    loadIdentifiedObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Map identifiedObjects to react-select options
  const speciesOptions = identifiedObjects.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  // Handle selection change
  const handleSelectChange = (selectedOptions: any) => {
    const selectedObjects = selectedOptions
      ? identifiedObjects.filter((item) =>
          selectedOptions.some((option: any) => option.value === item.id)
        )
      : [];
    setSelectedIdentifiedObjects(selectedObjects);
  };
  // Reset local state when resetTrigger changes
  useEffect(() => {
    setSelectedIdentifiedObjects([]);
  }, [resetTrigger, setSelectedIdentifiedObjects]);

  return (
    <>
      <div className="mb-2">
        <span className="d-block fw-bold mb-1">
          <small>Identified Species:</small>
        </span>
        <div>
          <Select
            inputId="species-select"
            isMulti={mode === "multi"} // Set multi-select based on mode
            options={speciesOptions}
            onChange={handleSelectChange}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select..."
            styles={{
              control: (provided: any) => ({
                ...provided,
                // backgroundColor: "#F5F8FB",
                borderColor: "#d1d7e0",
                fontSize: 13,
              }),
              placeholder: (provided: any) => ({
                ...provided,
                color: "#506690",
              }),
              option: (provided: any) => ({
                ...provided,
                fontSize: 13,
              }),
            }}
          />
        </div>
      </div>
    </>
  );
};

export default IdentifiedSpecies;
