import { useEffect, useState } from "react";
import BarChart from "./BarChart";

import { CHART_BG_COLORS, CHART_BORDER_COLORS } from "../../utils/reports";
import {
  IdentifiedObjectItem,
  IdentifiedSpeciesCount,
} from "../../models/Media";
import { CameraItem } from "../../models/Camera";

interface SpeciesByYearProps {
  cameras: CameraItem[];
  selectedCameras: CameraItem[];
  allIdentifiedObjects: IdentifiedObjectItem[];
  selectedIdentifiedObjects: IdentifiedObjectItem[];
  reportData: IdentifiedSpeciesCount[];
  yearNames: string[];
}

export const getTotalCount = (
  object_name: string,
  yearName: string,
  reportData: IdentifiedSpeciesCount[],
  selectedCameras: CameraItem[]
): number | null => {
  let total_count = 0;
  if (reportData) {
    let filteredReportData = reportData?.filter(
      (data: any) => object_name === data.identified_object_name
    );

    for (const data of filteredReportData) {
      for (const camera of selectedCameras) {
        // Convert the date from the data to a JavaScript Date object
        const dataDate = new Date(data.date);
        // Check if the date is within the selected range, if provided
        const isWithinDateRange =
          yearName === dataDate.getFullYear().toString();

        if (isWithinDateRange && camera.camera_id === data.camera)
          total_count += data.count;
      }
    }
    return total_count > 0 ? total_count : null;
  }

  return null;
};
export const getCount = (
  object_name: string,
  yearName: string,
  camera: string,
  reportData: IdentifiedSpeciesCount[]
): number => {
  let total_count = 0;
  if (reportData) {
    let filteredReportData = reportData?.filter(
      (data: any) => object_name === data.identified_object_name
    );

    for (const data of filteredReportData) {
      // Convert the date from the data to a JavaScript Date object
      const dataDate = new Date(data.date);
      // Check if the date is within the selected range, if provided
      const isWithinYear = dataDate.getFullYear().toString() === yearName;

      if (isWithinYear && camera === data.camera) total_count += data.count;
    }
    return total_count;
  }

  return 0;
};
const LocationSpeciesByYear = ({
  cameras,
  selectedCameras,
  allIdentifiedObjects,
  selectedIdentifiedObjects,
  reportData,
  yearNames,
}: SpeciesByYearProps) => {
  const [mapData, setMapData] = useState<any>(null);
  const [csvData, setCsvData] = useState<any>();
  const ChartName = "Location Species by Year";

  // Create the customized formatted data for exporting CSV file
  const csvArrays: any = (yearName: string) =>
    selectedCameras?.flatMap((camera) => {
      return selectedIdentifiedObjects?.map((object) => {
        let regionObject: any = {
          camera: camera.camera_id,
          Object: object.name,
          Year: yearName,
          Count: getCount(object.name, yearName, camera.camera_id, reportData),
        };
        return regionObject;
      });
    });

  // Use useEffect to update mapData when regions or selectedSpecies change
  useEffect(() => {
    // Load all csv related data
    const csv = yearNames?.flatMap((yearName) =>
      csvArrays(yearName.toString())
    );
    setCsvData(csv);
  }, [selectedCameras, selectedIdentifiedObjects, yearNames, reportData]);

  // Use useEffect to update mapData when regions or selectedSpecies change
  useEffect(() => {
    if (selectedCameras && selectedIdentifiedObjects) {
      const data = {
        labels: yearNames?.map((yearname) => yearname.toString()),
        datasets:
          selectedIdentifiedObjects?.map((objectName, index) => ({
            label: objectName.name,
            data: yearNames?.map((yearname) =>
              getTotalCount(
                objectName.name,
                yearname.toString(),
                reportData,
                selectedCameras
              )
            ),
            backgroundColor:
              CHART_BG_COLORS[Object.keys(CHART_BG_COLORS)[index]],
            borderColor:
              CHART_BORDER_COLORS[Object.keys(CHART_BORDER_COLORS)[index]],
            borderWidth: 1,
          })) ?? [],
      };
      setMapData(data);
    }
  }, [selectedCameras, selectedIdentifiedObjects, yearNames, reportData]);

  const getYAxisConfig = (datasets: any[]) => {
    if (!datasets || datasets.length === 0) {
      return { suggestedMax: 10, stepSize: 1 };
    }

    // Extract all count values from datasets
    const allValues = datasets.flatMap((dataset) =>
      dataset.data.map((d: any) => (d !== null && d !== undefined ? d : 0))
    );

    const maxValue = Math.max(...allValues);

    let stepSize = 1;
    let suggestedMax = 10;

    if (maxValue < 10) {
      stepSize = 1;
      suggestedMax = 10;
    } else if (maxValue < 70) {
      stepSize = 10;
      suggestedMax = Math.ceil(maxValue / 10) * 10;
    } else if (maxValue < 350) {
      stepSize = 50;
      suggestedMax = Math.ceil(maxValue / 50) * 50;
    } else if (maxValue < 1000) {
      stepSize = 50;
      suggestedMax = Math.ceil(maxValue / 50) * 50;
    } else {
      stepSize = 200; // Adjust as needed for larger max values
      suggestedMax = Math.ceil(maxValue / 200) * 200;
    }

    return { suggestedMax, stepSize };
  };

  // Using mapData conditionally to avoid error
  const { suggestedMax, stepSize } = mapData
    ? getYAxisConfig(mapData.datasets)
    : { suggestedMax: 10, stepSize: 1 };

  // Define chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Species Observed by Year",
        position: "top",
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 10,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      y: {
        min: 0,
        suggestedMax: suggestedMax,
        ticks: {
          stepSize: stepSize,
        },
      },
    },
  };

  return (
    <BarChart
      mapData={mapData}
      options={options}
      csvData={csvData}
      name={ChartName}
    />
  );
};

export default LocationSpeciesByYear;
