import { useEffect, useState } from "react";
import BarChart from "./BarChart";
import { CameraItem } from "../../models/Camera";
import {
  IdentifiedObjectItem,
  IdentifiedSpeciesCount,
} from "../../models/Media";
import {
  CHART_BG_COLORS,
  CHART_BORDER_COLORS,
  check_date_within_range,
} from "../../utils/reports";

interface AllSpeciesProps {
  cameras: CameraItem[];
  selectedCameras: CameraItem[];
  allIdentifiedObjects: IdentifiedObjectItem[];
  selectedIdentifiedObjects: IdentifiedObjectItem[];
  reportData: IdentifiedSpeciesCount[];
  startDate: string;
  endDate: string;
}
export const getTotalCount = (
  reportData: IdentifiedSpeciesCount[],
  cameras: CameraItem[],
  object_name: string,
  startDate: string,
  endDate: string
): number | null => {
  let total_count = 0;
  if (reportData) {
    const filteredReportData = reportData.filter(
      (data) => object_name === data.identified_object_name
    );

    for (const data of filteredReportData) {
      for (const camera of cameras) {
        const isWithinDateRange = check_date_within_range(
          startDate,
          endDate,
          data.date
        );
        if (isWithinDateRange && camera.camera_id === data.camera) {
          total_count += data.count;
        }
      }
    }
    return total_count > 0 ? total_count : null;
  }

  return 0;
};
export const getCount = (
  reportData: IdentifiedSpeciesCount[],
  object_name: string,
  camera: string,
  startDate: string,
  endDate: string
): number => {
  let count = 0;
  if (reportData) {
    let filteredReportData = reportData.filter(
      (data: any) => object_name === data.identified_object_name
    );

    for (const data of filteredReportData) {
      // for (const camera of cameras) {
      const isWithinDateRange = check_date_within_range(
        startDate,
        endDate,
        data.date
      );
      if (isWithinDateRange && camera === data.camera) count += data.count;
    }
    return count;
  }

  return 0;
};
export const AllSpecies = ({
  cameras,
  selectedCameras,
  allIdentifiedObjects,
  selectedIdentifiedObjects,
  reportData,
  startDate,
  endDate,
}: AllSpeciesProps) => {
  const [csvData, setCsvData] = useState<any>();
  const [mapData, setMapData] = useState<any>(null);
  const ChartName = "All Species";

  // Create the customized formatted data for exporting CSV file
  const csvArrays: any = (objectName: string) =>
    cameras?.flatMap((camera: any, index: any) => {
      let allSpeciesObject: any = {
        camera: camera.camera_id,
        Object: objectName,
        Count: getCount(
          reportData,
          objectName,
          camera.camera_id,
          startDate,
          endDate
        ),
      };
      return allSpeciesObject;
    });

  useEffect(() => {
    // Load all csv related data
    const csv = allIdentifiedObjects?.flatMap((object) =>
      csvArrays(object.name)
    );
    setCsvData(csv);
  }, [
    selectedCameras,
    selectedIdentifiedObjects,
    startDate,
    endDate,
    reportData,
  ]);

  useEffect(() => {
    if (selectedCameras && selectedIdentifiedObjects) {
      const allSpecies = selectedIdentifiedObjects.map(
        (species) => species.name
      );
      const totalCounts = allSpecies.map(
        (speciesName) =>
          getTotalCount(reportData, cameras, speciesName, startDate, endDate) ||
          0
      );
      const data = {
        labels: allSpecies,
        datasets: [
          {
            data: totalCounts,
            backgroundColor: Object.values(CHART_BG_COLORS),
            borderColor: Object.values(CHART_BORDER_COLORS),
            borderWidth: 1,
          },
        ],
      };

      setMapData(data);
    }
  }, [
    selectedCameras,
    selectedIdentifiedObjects,
    reportData,
    startDate,
    endDate,
  ]);

  const getYAxisConfig = (datasets: any[]) => {
    if (!datasets || datasets.length === 0) {
      return { suggestedMax: 10, stepSize: 1 };
    }

    // Extract all count values from datasets
    const allValues = datasets.flatMap((dataset) =>
      dataset.data.map((d: any) => (d !== null && d !== undefined ? d : 0))
    );

    const maxValue = Math.max(...allValues);

    let stepSize = 1;
    let suggestedMax = 10;

    if (maxValue < 10) {
      stepSize = 1;
      suggestedMax = 10;
    } else if (maxValue < 70) {
      stepSize = 10;
      suggestedMax = Math.ceil(maxValue / 10) * 10;
    } else if (maxValue < 350) {
      stepSize = 50;
      suggestedMax = Math.ceil(maxValue / 50) * 50;
    } else if (maxValue < 1000) {
      stepSize = 50;
      suggestedMax = Math.ceil(maxValue / 50) * 50;
    } else {
      stepSize = 200; // Adjust as needed for larger max values
      suggestedMax = Math.ceil(maxValue / 200) * 200;
    }

    return { suggestedMax, stepSize };
  };

  // Using mapData conditionally to avoid error
  const { suggestedMax, stepSize } = mapData
    ? getYAxisConfig(mapData.datasets)
    : { suggestedMax: 10, stepSize: 1 };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Total Observed Species Count",
        position: "top" as const,
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 10,
        top: 10,
        bottom: 0,
      },
    },
    scales: {
      y: {
        min: 0,
        suggestedMax: suggestedMax,
        ticks: {
          stepSize: stepSize,
        },
      },
    },
  };

  return (
    <BarChart
      mapData={mapData}
      options={options}
      csvData={csvData}
      name={ChartName}
    />
  );
};
