import { Button } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { useRef, useCallback } from "react";
import { CSVLink } from "react-csv";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import DataLabels plugin
import { Chart, registerables } from "chart.js";

// Register the Chart.js components and plugins
Chart.register(...registerables);
Chart.register(ChartDataLabels); // Register the DataLabels plugin

interface BarChartProps {
  mapData: any;
  options: any;
  csvData: any;
  name: string;
}

const BarChart: React.FC<BarChartProps> = ({
  mapData,
  options,
  csvData,
  name,
}) => {
  const fileName = name + ".csv";
  let ref = useRef<any>(null);

  // const handleClick = useCallback(() => {
  //   const link = document.createElement("a");
  //   link.download = name + ".jpg";
  //   if (ref.current) link.href = ref.current.toBase64Image();
  //   link.click();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  const handleClick = useCallback(() => {
    if (ref.current) {
      // Get the chart's image as a base64 PNG string
      const pngImage = ref.current.toBase64Image();

      // Create an offscreen canvas for JPG conversion
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const img = new Image();

      img.onload = () => {
        // Set canvas dimensions to match the image
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the PNG image onto the canvas
        if (context) {
          context.fillStyle = "white"; // Set a white background
          context.fillRect(0, 0, canvas.width, canvas.height); // Fill canvas with white
          context.drawImage(img, 0, 0);
        }

        // Convert the canvas content to a JPG base64 string
        const jpgImage = canvas.toDataURL("image/jpeg", 1.0); // High-quality JPG

        // Create a download link for the JPG
        const link = document.createElement("a");
        link.href = jpgImage;
        link.download = `${name}.jpg`;
        link.click();
      };

      // Set the PNG image source to the new Image object
      img.src = pngImage;
    } else {
      console.error("Chart reference is not available.");
    }
  }, [name]);

  console.log("map data from barchart", mapData);

  // Define options for displaying datalabels on the bars
  const chartOptions = {
    ...options,
    plugins: {
      ...options.plugins,
      datalabels: {
        display: false, // This disables showing labels on the bars
      },
      // datalabels: {
      //   display: true, // Show data labels
      //   color: "black", // Label color
      //   anchor: "end",
      //   align: "top",
      //   rotation: (context: any) => {
      //     const object = context.dataset.data[context.dataIndex].object;
      //     return object && 90;
      //   },
      //   font: {
      //     weight: "bold",
      //     size: 11,
      //   },
      //   formatter: (value: any) => {
      //     const object = value?.object;

      //     if (object) {
      //       if (object.count > 0) {
      //         return `${object.name}: ${object.count}`;
      //       } else {
      //         return null;
      //       }
      //     }

      //     return value;
      //   },
      // },
    },
  };

  // Render the chart if data is available, otherwise display a message
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
          marginRight: "-10px",
          gap: "10px",
        }}
      >
        <div>
          {/* CSV Export Button */}
          {csvData && (
            <CSVLink
              filename={fileName}
              data={csvData}
              className="btn btn-success btn-sm"
            >
              Export to CSV
            </CSVLink>
          )}
        </div>
        <div>
          {/* PNG Export Button */}
          <Button variant="danger" size="sm" onClick={handleClick}>
            Export Chart to Image
          </Button>
        </div>
      </div>

      {/* Bar Chart Container */}
      <div
        style={{
          backgroundColor: "white",
          padding: "15px",
          marginRight: "-10px",
          height: "97%",
        }}
      >
        {mapData && mapData?.datasets?.length > 0 ? (
          <Bar ref={ref} options={chartOptions} data={mapData} />
        ) : (
          <div className="m-3">No Data to display.</div>
        )}
      </div>
    </>
  );
};

export default BarChart;
