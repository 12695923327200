import React, { createContext, useCallback, useContext, useState } from "react";
import { IdentifiedObjectItem } from "../models/Media";

interface SelectedIdentifiedObjectContextProps {
  allIdentifiedObjects: IdentifiedObjectItem[] | null;
  setAllIdentifiedObjects: React.Dispatch<
    React.SetStateAction<IdentifiedObjectItem[] | null>
  >;
  selectedIdentifiedObjects: IdentifiedObjectItem[] | null;
  setSelectedIdentifiedObjects: React.Dispatch<
    React.SetStateAction<IdentifiedObjectItem[] | null>
  >;
  resetState: () => void; // Function to reset the state
  resetTrigger: number; // A unique key to trigger resets
}

export const SelectedIdentifiedObjectContext =
  createContext<SelectedIdentifiedObjectContextProps>({
    allIdentifiedObjects: null,
    setAllIdentifiedObjects: () => {},
    selectedIdentifiedObjects: null,
    setSelectedIdentifiedObjects: () => {},
    resetState: () => {},
    resetTrigger: 0,
  });

export const useSelectedIdentifiedObjectContext = () =>
  useContext(SelectedIdentifiedObjectContext);

const SelectedIdentifiedObjectProvider: React.FC<
  React.PropsWithChildren<{}>
> = ({ children }) => {
  const [selectedIdentifiedObjects, setSelectedIdentifiedObjects] = useState<
    IdentifiedObjectItem[] | null
  >(null);
  const [allIdentifiedObjects, setAllIdentifiedObjects] = useState<
    IdentifiedObjectItem[] | null
  >(null);
  const [resetTrigger, setResetTrigger] = useState(0);

  // Reset function that triggers a state reset
  const resetState = useCallback(() => {
    setSelectedIdentifiedObjects(null);
    setAllIdentifiedObjects(null);
    setResetTrigger((prev) => prev + 1); // Update trigger to notify listeners
  }, []);
  return (
    <SelectedIdentifiedObjectContext.Provider
      value={{
        allIdentifiedObjects,
        setAllIdentifiedObjects,
        selectedIdentifiedObjects,
        setSelectedIdentifiedObjects,
        resetState,
        resetTrigger,
      }}
    >
      {children}
    </SelectedIdentifiedObjectContext.Provider>
  );
};

export default SelectedIdentifiedObjectProvider;
